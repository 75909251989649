import React, { useEffect } from 'react';
import * as eva from '@eva-design/eva';
import { useSelector } from 'react-redux';
import { createStackNavigator } from '@react-navigation/stack';
import { ApplicationProvider } from '@ui-kitten/components';
import { ThemeService } from '@ui-kitten/components/theme/theme/theme.service';
import lodashIsEmpty from 'lodash/isEmpty';

import DrawerNavigator from './DrawerNavigator/Branded';
import RootNavigatorBase from './RootNavigatorBase';

import Checkout from '../Screens/Checkout/CheckoutWebView.branded';
import { shopSelector } from '../RTK/shop/selectors';

const Stack = createStackNavigator();

function BrandedWebsiteNavigator() {
  const shopData = useSelector(shopSelector);
  const website_theme = !lodashIsEmpty(shopData?.theme)
    ? JSON.parse(shopData?.theme)
    : {};

  const store_theme = ThemeService.create({
    ...website_theme,
    'text-primary-color': '#80807B',
    'color-success-default': website_theme.icon_color,
    'color-success-hover': website_theme.icon_color,
    'color-success-focus': website_theme.icon_color,
    'color-success-active': website_theme.icon_color,
    'color-basic-default': website_theme.icon_color,
    'color-basic-hover': website_theme.icon_color,
    'color-basic-focus': website_theme.icon_color,
    'color-basic-active': website_theme.icon_color,
    'color-primary-default': website_theme.icon_color,
    'color-primary-default-border': website_theme.icon_color,
    'color-primary-transparent-hover': `${website_theme.icon_color}29`,
    'color-primary-500': website_theme.icon_color,
  });

  return (
    <ApplicationProvider {...eva} theme={{ ...eva.light, ...store_theme }}>
      <RootNavigatorBase>
        <Stack.Navigator
          screenOptions={{ headerShown: false }}
          initialRouteName="order"
        >
          <Stack.Screen name={'order'} component={DrawerNavigator} />
          <Stack.Screen name={'checkout'} component={Checkout} />
        </Stack.Navigator>
      </RootNavigatorBase>
    </ApplicationProvider>
  );
}

export default BrandedWebsiteNavigator;
