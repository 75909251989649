import Routes from './Routes/index.branded';
import App from './main';

export default function () {
  return (
    <App>
      <Routes />
    </App>
  );
}
